import * as styled from './NavbarElements'
import React from 'react'
import { FaBars } from "react-icons/fa";
import logo from "../../images/logo.png";


export const AboutItem = () => {
  return (
    <styled.NavItem>
      <styled.NavLink to='/about'>
      关于
      </styled.NavLink>
    </styled.NavItem>
  )
}

export const GuardianshipItem = () => {
  return (
    <styled.NavItem>
      <styled.NavLink to='/guardianship'>
      监护
      </styled.NavLink>
    </styled.NavItem>
  )
}

export const HomestayItem = () => {
  return (
    <styled.NavItem>
      <styled.NavLink to='/homestay'>
      家庭寄宿
      </styled.NavLink>
    </styled.NavItem>
  )
}

export const EducationItem = () => {
  return (
    <styled.NavItem>
      <styled.NavLink to='/educationServices'>
      教育服务
      </styled.NavLink>
    </styled.NavItem>
  )
}

export const SupportItem = () => {
  return (
    <styled.NavItem>
      <styled.NavLink to='/supportServices'>
      支持服务
      </styled.NavLink>
    </styled.NavItem>
  )
}

export const BlogItem = () => {
  return (
    <styled.NavItem>
      <styled.NavLink to='/blog'>
        Blog
      </styled.NavLink>
    </styled.NavItem>
  )
}

export const WorkItem = () => {
  return (
    <styled.NavItem>
      <styled.NavLink to='/workwithus'>
        Work With Us
      </styled.NavLink>
    </styled.NavItem>
  )
}

export const ContactItem = () => {
  return (
    <styled.NavItem>
      <styled.NavLink to='/contact'>
      联系方式
      </styled.NavLink>
    </styled.NavItem>
  )
}

export const ScrollAboutItem = () => {
  return (
    <styled.NavItem>
      <styled.ScrollNavLink to='about' smooth={true}>
      关于
      </styled.ScrollNavLink>
    </styled.NavItem>
  )
}

export const ScrollGuardianshipItem = () => {
  return (
    <styled.NavItem>
      <styled.ScrollNavLink to='guardianship' smooth={true}>
      监护
      </styled.ScrollNavLink>
    </styled.NavItem>
  )
}

export const ScrollHomestayItem = () => {
  return (
    <styled.NavItem>
      <styled.ScrollNavLink to='homestay' smooth={true}>
      家庭寄宿
      </styled.ScrollNavLink>
    </styled.NavItem>
  )
}

export const ScrollEducationItem = () => {
  return (
    <styled.NavItem>
      <styled.ScrollNavLink to='educationServices' smooth={true}>
      教育服务
      </styled.ScrollNavLink>
    </styled.NavItem>
  )
}

export const ScrollSupportItem = () => {
  return (
    <styled.NavItem>
      <styled.ScrollNavLink to='supportServices' smooth={true}>
      支持服务
      </styled.ScrollNavLink>
    </styled.NavItem>
  )
}

export const ScrollBlogItem = () => {
  return (
    <styled.NavItem>
      <styled.ScrollNavLink to='blog' smooth={true}>
        Blog
      </styled.ScrollNavLink>
    </styled.NavItem>
  )
}

export const ScrollWorkItem = () => {
  return (
    <styled.NavItem>
      <styled.ScrollNavLink to='workwithus' smooth={true}>
        Work With Us
      </styled.ScrollNavLink>
    </styled.NavItem>
  )
}

export const ScrollContactItem = () => {
  return (
    <styled.NavItem>
      <styled.ScrollNavLink to='contact' smooth={true}>
      联系方式
      </styled.ScrollNavLink>
    </styled.NavItem>
  )
}

export const MobileIconItem = ({toggle}) => {
  return (
    <styled.MobileIcon onClick={toggle}>
      <FaBars/>
    </styled.MobileIcon>
  )
}

export const NavLogoItem = () => {
  return (
    <styled.NavLogo to="/">
      <img src={logo} alt={<div>logo</div>} width='100%'/>
    </styled.NavLogo>
  )
}