import React from 'react'
import { Icons } from '../Footer/Social';
import { MNBContainer, Icon, CloseIcon, MNBWrapper, MNBLink, MNBMenu, MNBIconContainer } from "./elements";

const MobileNavbar = ({isOpen, toggle}) => {
  return (
    <MNBContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon/>
      </Icon>
      <MNBWrapper>
        <MNBMenu>
          <MNBLink onClick={toggle} to='/about'>
          关于
          </MNBLink>
          <MNBLink onClick={toggle} to='/guardianship'>
          监护
          </MNBLink>
          <MNBLink onClick={toggle} to='/homestay'>
          家庭寄宿
          </MNBLink>
          <MNBLink onClick={toggle} to='/educationServices'>
          教育服务
          </MNBLink>
          <MNBLink onClick={toggle} to='/supportServices'>
          支持服务
          </MNBLink>
          <MNBLink onClick={toggle} to='/contact'>
          联系我们
          </MNBLink>
          <MNBIconContainer>
            <Icons/>
          </MNBIconContainer>
        </MNBMenu>
      </MNBWrapper>
    </MNBContainer>
  )
}

export default MobileNavbar