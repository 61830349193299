import React from 'react'
import {InsertedTestimony, Testimony} from './TestimonialElements'
import Carousel from 'react-bootstrap/Carousel'
export {InsertedTestimony}

export const HomepageTestimony = () => {
  return (
    <Testimony quote="事实证明，Study Links International 对待所负责的孩子的态度是完全可靠的，他们对孩子的管理面面俱到并且充满关怀。" author="瑞思沃思学校校长"/>
  )
}

export const AboutTestimony = () => {
  return (
    <Testimony quote="海外家庭发现他们的问题和任何疑虑都会得到及时和善意的处理。学校、寄宿家庭和家长对有效沟通非常积极，他们感到 Study Links 的专业行动有效地支持了他们。" author="AEGIS 复检，2021 年"/>
  )
}

export const EducationTestimony = () => {
  return (
    <Testimony quote="我写信是为了感谢您帮助安排对昆西的个人指导。我和妻子对这些安排确实很满意。昆西觉得这些指导很有用，并在学校里取得了进步。" author="Ronson的家长郭先生"/>
  )
}

export const SupportTestimony = () => {
  return (
    <Testimony quote="非常感谢 Study Links如果没有 Study Links，就不会有今天的我。我向你们致以良好的祝愿，我很高兴看到越来越多的学生得到我曾经拥有的那份幸运和指导。" author="Dominic Wang - 圣母初级学院学生"/>
  )
}

export const TestimonialCarousel = () => {
  return (
    <Carousel fade>
      <Carousel.Item interval={2000}>
        <Testimony quote="Study Links International 提供了非常出色的家庭寄宿计划。它为受其照顾的学生提供良好、合适的寄宿家庭和住宿环境，并且始终高效且可联。." author="哈罗盖特女子学院寄宿主任" />
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <Testimony quote="事实证明，Study Links International 对待所负责的孩子的态度是完全可靠的，他们对孩子的管理面面俱到并且充满关怀。" author="瑞思沃思学校校长"/>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <Testimony quote="我写信是为了感谢您帮助安排对昆西的个人指导。我和妻子对这些安排确实很满意。昆西觉得这些指导很有用，并在学校里取得了进步。" author="Ronson的家长郭先生"/>
      </Carousel.Item>
    </Carousel>
    );
};

export const GuardianshipTestimonialCarousel = () => {
  return (
    <Carousel fade>
      <Carousel.Item interval={2000}>
        <Testimony quote="把孩子送到英国对我来说是一个很难的决定。但有了 Study Links 提供的贴心和专业的监护服务，我相信我的孩子一切会顺利的。他们关注学业进步，这对我来说非常重要，并就如何改进和继续在学校发展提出了想法。通过电子邮件或电话就可以联系到团队，他们总是愿意帮助我。我会向其他家长推荐这项服务。" author="王太太 - 家长" />
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <Testimony quote="毫无疑问，在英国学习期间，Study Links 给了我最好的照顾。我觉得我像是加入了一个大家庭。我结交了的一些终生难忘的朋友，他们来自世界各地。在英国的学校现在感觉就像在家一样，我得到了我需要的所有帮助。当我第一次来到英国时，Study Links 帮助我很快适应了这个全新的环境。它不仅仅是监护，更像是“家庭之船”。." author="Sam Chen – 阿克沃思学校的学生"/>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <Testimony quote="如果我在英国遇到任何问题，我要做的第一件事就是告诉 Study Links，因为他们会给你解决问题的方法。" author="Billy Kwong – 弗灵霍尔学校学生"/>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <Testimony quote="初到英国，我对陌生的环境和面孔感到震惊，这让我感到焦虑和想家。Study Links 给予了我在英国学习的信心。我校的其他学生也对 Study Links 给予了很好的评价。我很高兴成为他们的学生之一。" author="Theresa Liang – 埃塞尔堡女王学院 "/>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <Testimony quote="我非常看重 Study Links 的判断力和专业精神，从不为注册学生担心。" author="P Shield 女士 - 哈罗盖特女子学院寄宿主任"/>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <Testimony quote="我的女儿已经被安吉拉和她的团队的照顾了四年。由于他们的专业经验和务实精神，团队对我女儿的照顾恰到好处，没有宠坏她，在她在英国的学习期间中给她进行了限定，从而加强了她的独立思考能力。尽管如此，在需要迅速干预和帮助的特殊情况下，Study Links 非常适合我女儿的直接和长期利益。" author="Vivian Cheung – 家长"/>
      </Carousel.Item>
    </Carousel>
    );
};
export const HomestayTestimonialCarousel = () => {
  return (
    <Carousel fade>
      <Carousel.Item interval={2000}>
      <Testimony quote="Study Links International 提供了非常出色的家庭寄宿计划。它为受其照顾的学生提供良好、合适的寄宿家庭和住宿环境，并且始终高效且可联。." author="哈罗盖特女子学院寄宿主任" />
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <Testimony quote="如果没有 Study Links，我女儿到英国时可能会走丢。从第一天起，他们就提供了父母想要给孩子但却因距离太远无法给予的帮助和支持。他们为我女儿找到了一个很棒的寄宿家庭，每次住宿都安排得井井有条。谢谢 Study Links！" author="陈太太 - 家长"/>
      </Carousel.Item>
    </Carousel>
    );
};
export const WorkwithusTestimonialCarousel = () => {
  return (
    <Carousel fade>
      <Carousel.Item interval={2000}>
        <Testimony quote="I am honoured and privileged to be part of such an organisation as Study Links as they don’t just take the finest care of all their students but their host families too." author="Cath- Homestay" />
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <Testimony quote="Study Links 一直是一家专业且积极进取的公司，运营效率高，且对所负责的海外学生充满关怀和照顾。" author="雷德学校校长"/>
      </Carousel.Item>
    </Carousel>
    );
};